import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ConnectionService } from '../services/connection/connection.service';
import { OnlineOrOfflineUserService } from '../services/offlineAuth/online-or-offline-user.service';

export const notAbpAuthGuard: CanActivateFn = async (route, state) => {
  const authService = inject(OnlineOrOfflineUserService);
  const connectionService = inject(ConnectionService);
  const router = inject(Router);
  let isAuthenticated = await authService.isAuthenticated();
  if (!isAuthenticated) {
      router.navigate(['/loginOffline']);
      return false;
  }
  return true;
};