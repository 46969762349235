import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { notAbpAuthGuard } from './core/guards/auth-guard.guard';
import { LoginComponent } from './modules/login/login/login.component';
import { MainComponent } from './modules/main/main.component';
import { AdminLayoutComponent } from './modules/admin/admin-layout/admin-layout.component';
import { authGuard } from '@abp/ng.core';

const routes: Routes = [
  {
    path: 'home',
    component: MainComponent,
    canActivate: [notAbpAuthGuard],
    pathMatch: 'prefix',
    children: [
      {
        path: '', 
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
      },
    ]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [authGuard],
    children: [
      { 
        path: '', redirectTo: 'dashboard', pathMatch: 'full' 
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'account',
        loadChildren: () => import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
      },
      {
        path: 'gdpr',
        loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
      },
      {
        path: 'identity',
        loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
      },
      {
        path: 'language-management',
        loadChildren: () => import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
      },
      {
        path: 'saas',
        loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
      },
      {
        path: 'chat',
        loadChildren: () => import('@volo/abp.ng.chat').then(m => m.ChatModule.forLazy()),
      },
      {
        path: 'audit-logs',
        loadChildren: () => import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
      },
      {
        path: 'openiddict',
        loadChildren: () => import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
      },
      {
        path: 'text-template-management',
        loadChildren: () => import('@volo/abp.ng.text-template-management').then(m => m.TextTemplateManagementModule.forLazy()),
      },
      {
        path: 'file-management',
        loadChildren: () => import('@volo/abp.ng.file-management').then(m => m.FileManagementModule.forLazy()),
      },
      {
        path: 'gdpr-cookie-consent',
        loadChildren: () => import('./modules/gdpr-cookie-consent/gdpr-cookie-consent.module').then(m => m.GdprCookieConsentModule),
      },
      {
        path: 'setting-management',
        loadChildren: () => import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
      },
    ]
  },
  {
    path: 'loginOffline',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: 'home',
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}