import { EvaluationType } from "./evaluationType.model";
import { ReportStatusHistory } from "./reportStatusHistory.model";

export class EvaluationReport {
    tenantId?: number;
    evaluationTypeId: number;
    evaluationType?: EvaluationType;
    reportStatusHistories?: ReportStatusHistory[];

    constructor() {
        this.tenantId = 0;
        this.evaluationTypeId = 0;
        this.evaluationType = new EvaluationType();
        this.reportStatusHistories = [];
    }
}