import { EvaluationReport } from "../evaluationReport.model";
import { ReportStatusHistory } from "../reportStatusHistory.model";
import { User } from "../user.model";

const evaluationReport = new EvaluationReport();
const reportStatusHistory = new ReportStatusHistory();
const userInstance = new User();

// Define a generic function to generate columns with a constraint
function generateColumns<T extends Record<string, any>>(instance: T): string {
  return (Object.keys(instance) as (keyof T)[]).join(',');
}

export const DBStores = {
  evaluationReport: {
    tableName: 'EvaluationReport',
    columns: generateColumns(evaluationReport),
  },
  reportStatusHistory: {
    tableName: 'ReportStatusHistory',
    columns: generateColumns(reportStatusHistory),
  },
  user: {
    tableName: 'User',
    columns: generateColumns(userInstance),
  }
};