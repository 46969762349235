import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { DBStores } from '../../models/indexedDb/indexedDb.store.model';
import { EvaluationReport } from '../../models/evaluationReport.model';
import { ReportStatusHistory } from '../../models/reportStatusHistory.model';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AppDatabaseService extends Dexie {
  evaluationReport!: Dexie.Table<EvaluationReport, string>;
  reportStatusHistory!: Dexie.Table<ReportStatusHistory, string>;
  user!: Dexie.Table<User, string>;

  versionNumber: number = 2;
  private dbName: string = 'index-db-app';

  constructor() {
    super('index-db-app');
    this.version(this.versionNumber).stores(this.setTablesSchema());

    this.evaluationReport = this.table<EvaluationReport, string>(DBStores.evaluationReport.tableName);
    this.reportStatusHistory = this.table<ReportStatusHistory, string>(DBStores.reportStatusHistory.tableName);
    this.user = this.table<User, string>(DBStores.user.tableName);
  }
  private setTablesSchema() {
    return Object.entries(DBStores).reduce((tables, [key, value]) => {
      tables[value.tableName] = value.columns;
      return tables;
    }, {} as Record<string, string>);
  }

  async clearDB() {
    this.close();
    await this.delete();
    await this.open();
  }
}