import { EvaluationReport } from "./evaluationReport.model";
import { ReportStatus } from "./reportStatus.model";

export class ReportStatusHistory {
    tenantId?: number;
    evaluationReportId: number;
    reportStatusId: number;
    timeStamp: Date;
    evaluationReport?: EvaluationReport;
    reportStatus?: ReportStatus;

    constructor() {
        this.tenantId = 0;
        this.evaluationReportId = 0;
        this.reportStatusId = 0;
        this.timeStamp = new Date();
        this.evaluationReport = new EvaluationReport();
        this.reportStatus = new ReportStatus();
    }
}