<section class="flex md:h-full flex-col items-center justify-between bg-pst-blue-100 relative transition-all ease-linear z-50" 
    [ngClass]="{'md:w-[250px] h-full': this.isOpened, 'md:w-[150px] h-auto md:!h-full': !this.isOpened}">
    
    <header class="flex justify-start items-center h-[46px] w-full text-pst-blue-10">
        <button mat-icon-button color="primary" (click)="this.toggleSidebar()" class="text-white">
            <mat-icon svgIcon="navbarButton">
            </mat-icon>
        </button>
    </header>

    @if (this.isOpened || !this.isMobileVersion) {
        <section class="flex-1 p-2 text-pst-blue-10 w-full">
            <a [routerLink]="['/evaluationType']" routerLinkActive="router-link-active" >Tipos de Recorridas</a>
        </section>
    
        <button class="bg-pst-blue-100 hover:bg-pst-blue-75 text-pst-blue-10 font-medium py-2 px-2 mt-2 3xl:text-2xl 
        transition-all ease-linear w-full text-center" [ngClass]="{'text-center': this.isOpened}" (click)="closeSession()">
            Cerrar Sesión
        </button>
    }
</section>