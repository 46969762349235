import { Component } from '@angular/core';
import { SPINNER_NAME } from './core/constants/constants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen]="true"
    [name]="this.spinnerName"
  >
    <p class="text-md text-white">Cargando...</p>
  </ngx-spinner>
  `,
})
export class AppComponent {
  spinnerName = SPINNER_NAME;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'navbarButton',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar/openButton.svg')
    );
  }
}
